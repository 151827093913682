<template>
  <div>
    <Preloader v-if="loading"/>
    <b-form @submit.prevent="handleSubmit">

      <b-tabs content-class="mt-3" fill v-model="tabIndex">
        <b-tab title="Шаблон" class="widget" active>
          <h4 class="mb-2 bold">Выберите шаблон</h4>
          <div class="email__card__container" v-if="step_data.templates">
            <EmailTemplateCard @checked="onCheckTemplate" v-for="item in step_data.templates"
                               :selected-id="selected.template" :card="item" :key="item.id"/>
            <div class="email__card__add">
              <div class="email__card__wrapper">
                <a href="https://cp.unisender.com/ru/v5/letter/template/create" target="_blank"><i
                    class="fa fa-plus"></i>создать</a>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab class="widget" title="Контакты">

          <div class="contact__list__container">
            <h4 class="my-2 bold">
              Какую базу контактов вы хотите использовать?
            </h4>
            <EmailContactList :contacts="step_data.emails" @contactPicked="handleContactPick"/>
          </div>

        </b-tab>

        <b-tab class="widget" title="Форма">
          <div class="email__campaign__form">
            <h4 class="mt-2 mb-0 bold">Введите тему и адрес</h4>
            <p>Тема и отправитель сразу заметны. Пусть письмо заинтересует с первого взгляда.</p>
            <div class="row">
              <div class="col-12">

                <b-form-group
                    description="Let us know your name."
                    label="Тема письма"
                    label-for="theme"
                >
                  <b-form-input id="theme" v-model="selected.theme" trim></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6">

                <b-form-group
                    description="Let us know your name."
                    label="Email отправителя"
                    label-for="sender"
                >
                  <select class="form__drop" v-model="selected.sender">
                    <option v-for="item in step_data.checked_emails" :value="item.email" :key="item.email">{{
                        item.email
                      }}
                    </option>
                  </select>
                  <!--              <b-form-input id="sender" v-model="selected.sender" trim></b-form-input>-->
                </b-form-group>
              </div>

              <div class="col-6">

                <b-form-group
                    description="Используйте ваше имя или название компании"
                    label="Email или название компании"
                    label-for="sender-name"
                >
                  <b-form-input id="sender-name" v-model="selected.sender_name" trim></b-form-input>
                </b-form-group>

              </div>

            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="row">
        <div class="col-12 text-right">
          <button class="btn btn-primary" type="submit">Создать</button>
        </div>
      </div>

    </b-form>

  </div>
</template>

<script>
import Preloader from "../../components/Preloader";
import EmailTemplateCard from "../../components/EmailTemplateCard";
import EmailContactList from "../../components/EmailContactList";
import MessageMixin from "../../mixins/MessageMixin";

export default {
  name: "EmailEdit",
  components: {EmailContactList, EmailTemplateCard, Preloader},
  mixins: [MessageMixin],
  data() {
    return {
      form: {},
      step: 1,
      loading: false,
      tabIndex: 0,
      showEmoji: false,
      step_data_map: {
        template: 'Шаблон',
        contact: 'Контакт',
        theme: 'Тема',
        sender: 'Отправитель',
        sender_name: 'Имя отправителя'
      },
      step_data: {
        templates: null,
        emails: null,
        checked_emails: null,
        selected_data: null,
      },
      selected: {
        template: null,
        contact: null,
        theme: null,
        sender: null,
        sender_name: null
      }
    }
  },
  computed: {
    editMode() {
      return !!(this.$route.name === 'EmailEdit' && this.$route.params.id);
    },
  },
  methods: {
    addData() {

    },

    handleContactPick(data) {
      this.selected.contact = data
    },

    async handleSubmit() {

    },
    // async handleSubmit() {
    //   let stepIdx = 0;
    //   console.log(this.selected)
    //   for (const key in this.selected) {
    //     if (this.selected[key] === null) {
    //       this.$toasted.error('Необходимо выбрать ' + this.step_data_map[key], {
    //         position: "bottom-right"
    //       })
    //       this.tabIndex = stepIdx;
    //       return
    //     }
    //     stepIdx++;
    //   }
    //
    //   try {
    //     const {data} = await this.$axios.post('api/mailing/email/message', {
    //         template_id: this.selected.template,
    //         contact_email: this.selected.sender,
    //         theme: this.selected.theme,
    //         sender_name: this.selected.sender_name,
    //         list_id: this.selected.contact
    //     });
    //     console.log(data)
    //     if (data && data.result) {
    //       this.$toasted.success('Успех', {
    //         position: "bottom-right"
    //       });
    //       await this.$router.push({name: 'EmailSend'})
    //     }
    //   } catch (e) {
    //     console.log(e)
    //     this.$toasted.error('Произошла ошибка', {
    //       position: "bottom-right"
    //     });
    //   }
    //
    // },

    toggleEmoji() {
      this.showEmoji = !this.showEmoji;
    },
    onCheckTemplate(data) {
      if (data && data.card) {
        this.selected.template = data.card.id
      }
    },
    toggleLoader() {
      this.loading = !this.loading
    },
    async nextStep() {
      this.tabIndex++;
    },
    async loadNext() {
      this.toggleLoader()
      try {
        const {data} = await this.$axios.get('/api/mailing/email/step', {
          params: {
            step: this.step
          }
        })
        if (data && data.result) {
          switch (this.step) {
            case 1:
              this.step_data.template = data.result
              break;
            case 2:
              this.step_data.contacts = data.result
          }
        }
        this.toggleLoader()
      } catch (e) {
        this.toggleLoader()
        console.log(e)
      }
    },
    async loadReqs() {
      try {
        this.toggleLoader()
        const {data} = await this.$axios.get(`api/mailing/email/message/${this.$route.params.id}/edit`);
        const requiredFields = ['checked_emails', 'emails', 'templates', 'selected_message']
        if (data) {
          for (const key in data) {
            if (requiredFields.includes(key)) {
              if (data[key] && data[key]['result']) {
                this.step_data[key] = data[key]['result']
              }
            }
          }
        }
        this.prepareData()
        this.toggleLoader()
        console.log('sssss', data);
      } catch (e) {
        this.toggleLoader()
        console.log(e)
      }
    },
    prepareData() {
      if (this.step_data.selected_data && this.step_data.selected_data['result']) {
        // const data = this.step_data.selected_data['result']
        // this.selected.contact = !Array.isArray(data.contact) ? data.contact : null
        // this.selected.theme =
      }
    }
  },
  async created() {
    await this.loadReqs();
  }
}
</script>

<style scoped lang="scss">
.widget__toolbar {
  padding: 5px 0px;
}

.btn__add {
  height: 30px;
  float: right;
}

.next {
  padding: 10px;
  text-align: center;
  color: #333;
  background: #0D2B47;
  border-radius: 5px;

  a {
    color: #fff;
    display: block;
    font-weight: bold;
  }
}

.emoji__button {
  display: inline-block;
}

.mailing__name {
  background: #F9FBFD !important;
  border: none;
  box-shadow: none !important;
  font-size: 24px;
  font-weight: bold;
}

.email__card__container {
  display: flex;
  flex-wrap: wrap;
}

.email__card__wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px dashed #eee;
}

.email__card__add {
  width: 20%;
  padding: 7.5px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #777;
    width: 100px;
    height: 100px;
    transition: .2s;

    &:hover {
      text-decoration: none;
      color: #333;
    }

    i {
      font-size: 26px;
    }
  }
}


.form {
  &__drop {
    padding: 5px 14px;
    width: 100%;
    border-radius: 5px;
    background: #fff;
  }
}

.bold {
  font-weight: bold;
}
</style>